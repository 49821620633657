<template>
  <main v-if="loaded">
    <DonationBanner />
    <div class="featured-events" v-if="featuredEvents">
      <p class="featured-title">Featured</p>
      <carousel
        :autoplay="true"
        :autoplayTimeout="4000"
        :perPage="1"
        :perPageCustom="[
          [768, 3],
          [1200, 3],
        ]"
      >
        <slide v-for="(event, index) in featuredEvents" :key="index">
          <a :href="event.event_url[0].text" target="_blank">
            <div class="featured-event">
              <div class="text">
                <p class="event-name">
                  <b> {{ $prismic.asText(event.eventname) }}</b>
                </p>
                <p class="sub-text small">
                  {{ $prismic.asText(event.subtext) }}
                </p>
              </div>
              <prismic-image class="feature-logo" :field="event.event_logo" />
            </div>
          </a>
        </slide>
      </carousel>
    </div>
    <div class="search-container">
      <div class="search">
        <input
          type="text"
          class="search-input"
          :class="{ active: textSearch.length > 0 }"
          v-model="textSearch"
          placeholder="Search for events and tours"
          @keypress.enter="$event.target.blur()"
          @keydown="debouncedEventSearch()"
          maxlength="60"
        />
        <Search color="midnight" class="search-icon" />
        <span
          class="clear"
          @click="textSearch = ''"
          :class="{ active: textSearch != '' }"
        >
          <FilterChipCross color="twilight" />
        </span>
      </div>
      <div class="upcoming-finished">
        <div
          :class="{ active: !includeFinishedEvents }"
          @click="includeFinishedEvents = false"
        >
          Upcoming
        </div>
        <div
          :class="{ active: includeFinishedEvents }"
          @click="includeFinishedEvents = true"
        >
          Finished
        </div>
      </div>

      <section class="view-picker">
        <ul>
          <li :class="{ active: view == 'DAY' }" @click="changeView('DAY')">
            Day
          </li>
          <li :class="{ active: view == 'WEEK' }" @click="changeView('WEEK')">
            Week
          </li>
          <li :class="{ active: view == 'MONTH' }" @click="changeView('MONTH')">
            Month
          </li>
          <li
            :class="{ active: view == 'ALL_TIME' }"
            @click="changeView('ALL_TIME')"
          >
            All time
          </li>
        </ul>
      </section>

      <div class="day-view" :class="animationDirection" v-show="view == 'DAY'">
        <div @click="backOneDay()" class="back">
          <NextPreviousArrow />
        </div>
        <transition-group name="list" tag="p" v-hammer:swipe="moveDates">
          <div
            v-for="day in listOfViewedDays"
            :key="day"
            class="list-item"
            :class="{ selected: selectedDateSelected(day) }"
            @click="dayViewDay = day"
          >
            <span class="date">{{ daysViewFormatted(day).date }}</span>
            <span class="day">{{ daysViewFormatted(day).day }}</span>
            <span class="month">{{ daysViewFormatted(day).month }}</span>
          </div>
        </transition-group>
        <div @click="forwardOneDay()" class="next">
          <NextPreviousArrow direction="right" />
        </div>
      </div>

      <div
        class="week-view"
        :class="animationDirection"
        v-show="view == 'WEEK'"
      >
        <div @click="backOneWeek()" class="back">
          <NextPreviousArrow />
        </div>

        <transition-group name="list" tag="p" v-hammer:swipe="moveDates">
          <div
            v-for="week in listOfViewedWeeks"
            :key="week.startDate"
            class="list-item"
            :class="{ selected: selectedWeekSelected(week) }"
            @click="weekViewWeek = week.startDate"
          >
            <span class="start">{{ weeksViewFormatted(week.startDate) }}</span>
            <span class="to">to</span>
            <span class="end">{{ weeksViewFormatted(week.endDate) }}</span>
          </div>
        </transition-group>
        <div @click="forwardOneWeek()" class="next">
          <NextPreviousArrow direction="right" />
        </div>
      </div>

      <div
        class="month-view"
        :class="animationDirection"
        v-show="view == 'MONTH'"
      >
        <div class="back" @click="backOneMonth()">
          <NextPreviousArrow />
        </div>

        <transition-group name="list" tag="p" v-hammer:swipe="moveDates">
          <div
            v-for="month in listOfViewedMonths"
            :key="month.startDate"
            class="list-item"
            :class="{ selected: selectedMonthSelected(month) }"
            @click="monthViewMonth = month.startDate"
          >
            <span class="between"
              >{{ monthViewFormatted(month.startDate) }}-{{
                monthViewFormatted(month.endDate)
              }}</span
            >
            <span class="month-large">{{ month.month }}</span>
            <span class="year">{{ month.year }}</span>
          </div>
        </transition-group>
        <div class="next" @click="forwardOneMonth()">
          <NextPreviousArrow direction="right" />
        </div>
      </div>

      <div class="all-time-view" v-if="view == 'ALL_TIME'">
        <div class="start">
          <p>From</p>
          <TjingDateTimePicker
            :canBeCleared="true"
            :readOnly="false"
            :options="allTimeDateRangeStart"
            :dateTime="allTimeDateRangeStart.date"
            @update="allTimeDateRangeStart.date = $event"
          />
        </div>
        <div class="end">
          <p>To</p>
          <TjingDateTimePicker
            :canBeCleared="true"
            :readOnly="false"
            :options="allTimeDateRangeEnd"
            :dateTime="allTimeDateRangeEnd.date"
            @update="allTimeDateRangeEnd.date = $event"
          />
        </div>
      </div>

      <div class="filters" v-click-outside="hideFilters">
        <div
          class="header"
          :class="{ 'filters-active': activeFiltersLength > 0 }"
        >
          <div
            @click="collapse()"
            class="filter-button left"
            :class="{ active: showFilters }"
          >
            <Filters
              :direction="showFilters ? 'down' : 'up'"
              :color="showFilters ? 'white' : 'midnight'"
            />
            <span v-if="!showFilters">Show filters</span>
            <span v-else>Hide filters</span>
          </div>
          <div
            @click="toggleShowOptions()"
            class="filter-button right"
            v-click-outside="hideShowOptions"
          >
            <span v-if="sortBy == 'DATE'">Sort by <b>Date</b></span>
            <span v-else>Sort by <b>Distance</b></span>
            <Chevron2 :direction="showSortOptions ? 'up' : 'down'" />
            <slide-up-down
              :active="showSortOptions"
              :duration="100"
              tag="div"
              class="dropdown"
            >
              <ul>
                <li
                  @click.stop="sortByAttribute('DISTANCE')"
                  :class="{ disabled: usersPosition == null }"
                >
                  Distance
                </li>
                <li @click.stop="sortByAttribute('DATE')">Date</li>
              </ul>
            </slide-up-down>
          </div>
        </div>
        <slide-up-down
          :active="activeFiltersLength > 0"
          :duration="500"
          tag="div"
          class="active-filters-wrapper"
        >
          <transition-group name="tags" tag="ul" class="active-filters">
            <li
              v-for="tourType in enabledTourTypes"
              :key="tourType.type"
              @click="toggleSelectedTourTypes(tourType)"
            >
              {{ tourType.name }}
              <FilterChipCross color="white" />
            </li>
            <li
              v-for="pdgaType in enabledpdgaTypes"
              :key="pdgaType.type"
              @click="toggleSelectedPDGATypes(pdgaType)"
            >
              {{ pdgaType.name }}
              <FilterChipCross color="white" />
            </li>
            <li v-if="city != null" :key="city.id" @click="city = null">
              {{ city.name }}
              <FilterChipCross color="white" />
            </li>
            <li v-if="country != null" :key="country" @click="country = null">
              {{ country }}
              <FilterChipCross color="white" />
            </li>
            <li
              class="clear-filters"
              key="clear"
              v-if="activeFiltersLength > 0"
              @click="clearFilters()"
            >
              Clear
            </li>
          </transition-group>
        </slide-up-down>
        <slide-up-down :active="showFilters" :duration="500" class="w-100">
          <section class="filter-open">
            <p class="tour-types" key="tours">Tour types</p>
            <ul>
              <li
                v-for="type in tourTypes"
                :key="type.type"
                :class="{ active: enabledTourTypes.includes(type) }"
                @click="toggleSelectedTourTypes(type)"
                class="list-complete-item"
              >
                <FilterChipCross
                  color="twilight"
                  class="icon left"
                  :class="{ active: !enabledTourTypes.includes(type) }"
                />
                {{ type.name }}
                <FilterChipCross
                  color="white"
                  class="icon right"
                  :class="{ active: enabledTourTypes.includes(type) }"
                />
              </li>
            </ul>
            <p class="pdga-tiers" key="tiers">PDGA Tiers</p>
            <ul>
              <li
                v-for="type in pdgaTypes"
                :key="type.type"
                :class="{ active: enabledpdgaTypes.includes(type) }"
                @click="toggleSelectedPDGATypes(type)"
                class="list-complete-item"
              >
                <FilterChipCross
                  color="twilight"
                  class="icon left"
                  :class="{ active: !enabledpdgaTypes.includes(type) }"
                />
                {{ type.name }}
                <FilterChipCross
                  color="white"
                  class="icon right"
                  :class="{ active: enabledpdgaTypes.includes(type) }"
                />
              </li>
            </ul>
            <p class="location" key="location">Location</p>
            <select name="city" v-model="city">
              <option :value="null" selected>Select city</option>
              <option
                v-for="city in citiesList"
                :value="city"
                :key="city.cityId"
              >
                {{ city.name }}
              </option>
            </select>

            <select name="country" v-model="country">
              <option :value="null" selected>Select country</option>
              <option
                v-for="country in countriesList"
                :value="country"
                :key="country"
              >
                {{ country }}
              </option>
            </select>

            <div class="footer">
              <p class="clear" @click.stop="clearFilters()">Clear filters</p>
              <p class="hide" @click.stop="collapse()">Hide filters</p>
            </div>
          </section>
        </slide-up-down>
      </div>
      <section class="events-list">
        <template v-if="filteredEventsList.length == 0">
          <div class="empty-search-messages">
            <p>
              No events found
              <template v-if="view == 'DAY'"
                >during
                <b v-if="isDateToday()">today</b>
                <b v-else-if="isDateTomorrow()">tomorrow</b>
                <b v-else-if="isDateYesterday()">yesterday</b>

                <b v-else-if="view == 'DAY'"
                  >{{ daysViewFormatted(dayViewDay).date }}
                  {{ daysViewFormatted(dayViewDay).day }}
                  {{ daysViewFormatted(dayViewDay).month }}</b
                >
              </template>
              <template v-if="view == 'WEEK'">
                during
                <b>{{ weeksViewFormattedStart(weekViewWeek) }}</b> to
                <b>{{ weeksViewFormattedEnd(weekViewWeek) }}</b></template
              >
              <template v-if="view == 'MONTH'"
                >during
                <b>{{ monthViewFormattedMessage(monthViewMonth) }}</b></template
              >
              <template v-if="view == 'ALL_TIME'">
                <template
                  v-if="
                    allTimeDateRangeStart.date == null &&
                    allTimeDateRangeEnd.date == null
                  "
                  >at all</template
                >
                <template
                  v-if="
                    allTimeDateRangeStart.date == null &&
                    allTimeDateRangeEnd.date !== null
                  "
                  >before <b>{{ weeksViewFormatted() }}</b></template
                >
                <template
                  v-if="
                    allTimeDateRangeStart.date !== null &&
                    allTimeDateRangeEnd.date == null
                  "
                  >during <b>{{ weeksViewFormatted() }}</b> and to the
                  <b> end of time</b></template
                >
                <template
                  v-if="
                    allTimeDateRangeStart.date !== null &&
                    allTimeDateRangeEnd.date !== null
                  "
                  >during
                  <b>{{ weeksViewFormatted(allTimeDateRangeStart.date) }}</b> to
                  <b>{{
                    weeksViewFormatted(allTimeDateRangeEnd.date)
                  }}</b></template
                >
              </template>
              <template v-if="textSearch != ''">
                for your search <b>{{ textSearch }}</b></template
              >.
            </p>

            <p v-if="view !== 'ALL_TIME'">
              Try a different date range such as
              <b class="ocean" @click="view = 'ALL_TIME'">All time</b>.
            </p>
            <p v-if="includeFinishedEvents == false">
              If you’re looking for a finished event then toggle to
              <b class="ocean" @click="includeFinishedEvents = true">Finished</b
              >.
            </p>
            <p v-else>
              If you’re looking for an upcoming event then toggle to
              <span @click="includeFinishedEvents = false">
                <b class="ocean">Upcoming</b></span
              >.
            </p>
          </div>
        </template>
        <template v-else>
          <div class="new-feature-push" v-if="showEventFavouriteNewsPush">
            <div class="content">
              <p>
                <Star />
                <b>
                  Hooray! You can now star events that you want to keep an eye
                  on. For convenience they’ll end up on your Dashboard.
                </b>
              </p>
            </div>
            <div class="close-push" @click="closeNewsPush()"><Crossmark /></div>
          </div>
          <p class="found-events-count">
            {{ filteredEventsList.length }}
            {{ eventTextFormatted(filteredEventsList.length) }} found
          </p>
          <TheFindEventsEventCard
            :eventData="event"
            :userTourPasses="tourPasses"
            :usersPosition="usersPosition"
            v-for="event in shownEvents"
            :key="event.id"
            @isFavourite="event.isUserFavourite = $event"
          />
        </template>
        <div
          class="show-more mt-4"
          v-if="filteredEventsList.length > numberOfShownEvents"
          @click="numberOfShownEvents += 30"
        >
          Show more events
        </div>
        <div
          class="back-to-top"
          v-if="filteredEventsList.length > 10"
          @click="toTop()"
        >
          Back to top
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import {
  isEqual,
  sortBy,
  filter,
  debounce,
  uniqWith,
  uniqBy,
  includes,
} from "lodash";
import locationMixin from "@/mixins/locationMixin.js";
import Search from "@/assets/icons/Search";
import TheFindEventsEventCard from "@/components/TheFindEventsEventCard";
import Chevron2 from "@/assets/icons/Chevron2";
import NextPreviousArrow from "@/assets/icons/NextPreviousArrow";
import FilterChipCross from "@/assets/icons/FilterChipCross";
import Filters from "@/assets/icons/Filters";
import Star from "@/assets/icons/Star";
import Crossmark from "@/assets/icons/Crossmark";
import moment from "moment";
import SlideUpDown from "vue-slide-up-down";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import ClickOutside from "vue-click-outside";
import DonationBanner from "@/components/DonationBanner";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Events",
  directives: {
    ClickOutside,
  },
  components: {
    Filters,
    SlideUpDown,
    FilterChipCross,
    TjingDateTimePicker,
    Search,
    TheFindEventsEventCard,
    NextPreviousArrow,
    Chevron2,
    DonationBanner,
    Crossmark,
    Star,
    Carousel,
    Slide,
  },
  mixins: [locationMixin],
  data() {
    return {
      loaded: false,
      featuredEvents: null,
      alltoursLoaded: false,
      tours: [],
      unsortedEventList: [],
      tourTypes: [],
      enabledTourTypes: [],
      pdgaTypes: [{ name: "Unsanctioned", type: "Unsanctioned" }],
      enabledpdgaTypes: [],
      startDate: {},
      endDate: {},
      includeFinishedEvents: false,
      view: null,
      listOfViewedDays: [],
      listOfViewedWeeks: [],
      listOfViewedMonths: [],
      animationDirection: "forward",
      dayViewDay: moment().startOf("day").toISOString(),
      weekViewWeek: moment().startOf("isoWeek").toISOString(),
      monthViewMonth: moment().startOf("month").toISOString(),
      textSearch: "",
      searchString: "",
      sortBy: "DATE",
      showSortOptions: false,
      displayedEvents: [],
      showFilters: false,
      numberOfShownEvents: 30,
      nearMe: false,
      country: null,
      city: null,
      citiesList: [],
      countriesList: [],
      usersPosition: null,
      searchRadiusKM: 25,
      allTimeDateRangeStart: {
        date: moment().startOf("day").toISOString(),
        minDateTime: moment().subtract(10, "years").startOf("year"),
        maxDateTime: moment().add(2, "years"),
        timeOnly: false,
        dateOnly: true,
        mode: "standard",
      },
      allTimeDateRangeEnd: {
        date: null,
        minDateTime: moment().subtract(10, "years").startOf("year"),
        maxDateTime: moment().add(2, "years"),
        timeOnly: false,
        dateOnly: true,
        mode: "standard",
      },
    };
  },

  watch: {
    "allTimeDateRangeStart.date": function (newVal, oldVal) {
      this.updateURLQuery();
    },
    "allTimeDateRangeEnd.date": function (newVal, oldVal) {
      this.updateURLQuery();
    },
    monthViewMonth: function (newVal, oldVal) {
      this.updateURLQuery();
    },
    weekViewWeek: function (newVal, oldVal) {
      this.updateURLQuery();
    },
    dayViewDay: function (newVal, oldVal) {
      this.updateURLQuery();
    },
    view: function (newVal, oldVal) {
      if (oldVal != null) {
        this.updateURLQuery();
      }
    },
    textSearch: function (newVal, oldVal) {
      if (newVal == "") {
        this.searchString = "";
      }
      this.updateURLQuery();
    },
    deviceWidth: function (newVal, oldVal) {
      if (newVal >= 1200 && oldVal <= 1200) {
        this.populateInitialDateViews();
      }
      if (oldVal >= 1200 && newVal <= 1200) {
        this.populateInitialDateViews();
      }
    },
    includeFinishedEvents: function (newVal, oldVal) {
      if (this.alltoursLoaded == false) {
        this.loadPastTours();
      }
      this.updateURLQuery();
    },
  },
  computed: {
    showEventFavouriteNewsPush() {
      const hasExpired = moment(this.eventFavouriteNewsPush.hidden).isBefore();

      if (hasExpired) {
        return false;
      }

      const hasBeenHidden = this.eventFavouriteNewsPush.hidden;

      if (hasBeenHidden) {
        if (
          this.eventFavouriteNewsPush.hidden &&
          this.eventFavouriteNewsPush.hideInterval == 0
        ) {
          return false;
        }

        return moment().isBefore(
          moment(this.eventFavouriteNewsPush.hidden).add(
            this.eventFavouriteNewsPush.hideInterval,
            "days"
          )
        );
      }
      return true;
    },
    eventFavouriteNewsPush() {
      return this.$store.state.temporaryCloseableElements.favouriteEventNews;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    tourPasses() {
      return this.$store.getters.tourPasses;
    },
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },

    shownEvents() {
      return this.filteredEventsList.slice(0, this.numberOfShownEvents);
    },
    activeFiltersLength() {
      let activeFilters = 0;

      activeFilters += this.enabledpdgaTypes.length;
      activeFilters += this.enabledTourTypes.length;
      if (this.nearMe) activeFilters++;
      if (this.city) activeFilters++;
      if (this.country) activeFilters++;

      return activeFilters;
    },
    dayView() {
      let day = moment(this.dayViewDay);

      let dayList = [
        day.subtract(3, "days").toISOString(),
        day.add(1, "days").toISOString(),
        day.add(1, "days").toISOString(),
        day.add(1, "days").toISOString(),
        day.add(1, "days").toISOString(),
        day.add(1, "days").toISOString(),
        day.add(1, "days").toISOString(),
      ];

      return dayList;
    },
    weekView() {
      let week = moment(this.weekViewWeek);

      let weekList = [
        week.subtract(2, "weeks").toISOString(),
        week.add(1, "weeks").toISOString(),
        week.add(1, "weeks").toISOString(),
        week.add(1, "weeks").toISOString(),
        week.add(1, "weeks").toISOString(),
      ];

      return weekList;
    },
    monthView() {
      let month = moment(this.monthViewMonth);

      let monthList = [
        month.subtract(2, "months").toISOString(),
        month.add(1, "months").toISOString(),
        month.add(1, "months").toISOString(),
        month.add(1, "months").toISOString(),
        month.add(1, "months").toISOString(),
      ];

      return monthList;
    },
    filteredEventsList() {
      let eventList = this.unsortedEventList;

      if (this.includeFinishedEvents == false) {
        eventList = eventList.filter((event) => event.status != "COMPLETED");
      } else {
        eventList = eventList.filter((event) => event.status == "COMPLETED");
      }

      if (this.view == "DAY") {
        let dayStart = moment(this.dayViewDay).startOf("day").toISOString();
        let dayEnd = moment(this.dayViewDay).endOf("day").toISOString();

        eventList = eventList.filter((event) => {
          return (
            moment(event.startDate).isBetween(dayStart, dayEnd) ||
            moment(event.endDate).isBetween(dayStart, dayEnd) ||
            (moment(event.startDate).isBefore(dayStart) &&
              moment(event.endDate).isAfter(dayEnd))
          );
        });
      }

      if (this.view == "WEEK") {
        let weekStart = this.weekViewWeek;
        let weekEnd = moment(this.weekViewWeek).endOf("isoWeek").toISOString();

        eventList = eventList.filter((event) => {
          return (
            moment(event.startDate).isBetween(weekStart, moment(weekEnd)) ||
            moment(event.endDate).isBetween(weekStart, moment(weekEnd))
          );
        });
      }

      if (this.view == "MONTH") {
        let monthStart = this.monthViewMonth;
        let monthEnd = moment(this.monthViewMonth).endOf("month").toISOString();

        eventList = eventList.filter((event) => {
          return (
            moment(event.startDate).isBetween(monthStart, moment(monthEnd)) ||
            moment(event.endDate).isBetween(monthStart, moment(monthEnd))
          );
        });
      }

      if (this.view == "ALL_TIME") {
        let startDate = moment(this.allTimeDateRangeStart.date).isValid()
          ? moment(this.allTimeDateRangeStart.date)
          : moment().subtract(5, "years");
        let endDate = moment(this.allTimeDateRangeEnd.date).isValid()
          ? moment(this.allTimeDateRangeEnd.date)
          : moment().add(2, "years");

        eventList = eventList.filter((event) => {
          return (
            moment(event.startDate).isBetween(startDate, moment(endDate)) ||
            moment(event.endDate).isBetween(startDate, moment(endDate))
          );
        });
      }

      if (this.country != null && this.city != null) {
        eventList = filter(eventList, (event) => {
          return (
            event.country == this.country || event.cityName == this.city.name
          );
        });
      } else if (this.city != null) {
        eventList = filter(eventList, { cityName: this.city.name });
      } else if (this.country != null) {
        eventList = filter(eventList, { country: this.country });
      }

      if (this.activeFiltersLength > 0) {
        let pdgaTypeList = [];
        let tourTypeList = [];

        if (this.enabledpdgaTypes.length > 0) {
          this.enabledpdgaTypes.forEach((type) => {
            pdgaTypeList.push(type.type);
          });

          eventList = filter(eventList, (event) => {
            return includes(pdgaTypeList, event.pdgaType.type);
          });
        }
        if (this.enabledTourTypes.length > 0) {
          this.enabledTourTypes.forEach((type) => {
            tourTypeList.push(type.type);
          });

          eventList = filter(eventList, (event) => {
            return includes(tourTypeList, event.tourType.type);
          });
        }
      }

      if (this.searchString.length > 1) {
        eventList = eventList.filter(
          (event) =>
            event.name
              .toLowerCase()
              .includes(this.searchString.toLowerCase()) ||
            event.tourName
              .toLowerCase()
              .includes(this.searchString.toLowerCase())
        );
      }

      eventList.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));

      if (this.sortBy == "DISTANCE") {
        eventList.sort((a, b) => {
          let AeventDistanceFromUser = this.getDistanceInKM(
            a.lat,
            a.lng,
            this.usersPosition.lat,
            this.usersPosition.lng
          );
          let BeventDistanceFromUser = this.getDistanceInKM(
            b.lat,
            b.lng,
            this.usersPosition.lat,
            this.usersPosition.lng
          );

          if (AeventDistanceFromUser > BeventDistanceFromUser) return 1;
          if (AeventDistanceFromUser < BeventDistanceFromUser) return -1;
          else return 0;
        });
      }

      return eventList;
    },
  },
  methods: {
    closeNewsPush() {
      this.$store.dispatch("updateTemporaryCloseableElement", {
        name: "favouriteEventNews",
      });
    },
    getContent() {
      this.$prismic.client
        .getSingle("featured_events")
        .then((document) => {
          if (document) {
            this.featuredEvents = document.data.featured_event;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleShowOptions() {
      this.showSortOptions = !this.showSortOptions;
    },
    hideShowOptions() {
      this.showSortOptions = false;
    },
    async sortByAttribute(attr) {
      try {
        this.usersPosition = await this.getLocation();
      } catch (err) {
        if (err.code == 1) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Location services disabled",
            type: "error",
          });
        }
      } finally {
        if (this.sortBy == "DATE") {
          if (this.usersPosition != null) {
            this.sortBy = attr;
          }
        } else {
          this.sortBy = attr;
        }

        this.showSortOptions = false;
      }
    },
    updateURLQuery() {
      let query = {
        finished: this.includeFinishedEvents ? "yes" : "no",
      };

      if (this.view == null) {
        if (this.$route.query.view) {
          this.view = this.$route.query.view;
        } else {
          this.view = "DAY";
        }
      }

      if (this.view == "DAY") {
        query.view = "DAY";
        query.startDate = this.dayViewDay;
      }
      if (this.view == "WEEK") {
        query.view = "WEEK";
        query.startDate = this.weekViewWeek;
      }
      if (this.view == "MONTH") {
        query.view = "MONTH";
        query.startDate = this.monthViewMonth;
      }
      if (this.view == "ALL_TIME") {
        query.view = "ALL_TIME";

        if (this.allTimeDateRangeStart.date) {
          query.startDate = moment(
            this.allTimeDateRangeStart.date
          ).toISOString();
        }
        if (this.allTimeDateRangeEnd.date) {
          query.endDate = moment(this.allTimeDateRangeEnd.date).toISOString();
        }
      }
      if (this.textSearch.length > 0) {
        query.search = this.textSearch;
      }

      this.$router.replace({ query }).catch(() => {});
    },
    eventTextFormatted(searchResultCount) {
      if (searchResultCount == 1) {
        return "event";
      } else {
        return "events";
      }
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    debouncedEventSearch: debounce(
      function () {
        this.searchString = this.textSearch;
      },
      500,
      { maxWait: 800 }
    ),
    moveDates(event) {
      if (event.direction == 2) {
        if (this.view == "DAY") {
          this.forwardOneDay();
        }
        if (this.view == "WEEK") {
          this.forwardOneWeek();
        }
        if (this.view == "MONTH") {
          this.forwardOneMonth();
        }
      } else if (event.direction == 4) {
        if (this.view == "DAY") {
          this.backOneDay();
        } else if (this.view == "WEEK") {
          this.backOneWeek();
        } else if (this.view == "MONTH") {
          this.backOneMonth();
        }
      }
    },
    isDateToday() {
      return moment(this.dayViewDay).isSame(moment(), "day");
    },
    isDateTomorrow() {
      return moment(this.dayViewDay).isSame(moment().add(1, "days"), "day");
    },
    isDateYesterday() {
      return moment(this.dayViewDay).isSame(
        moment().subtract(1, "days"),
        "day"
      );
    },
    clearFilters() {
      this.enabledTourTypes = [];
      this.enabledpdgaTypes = [];
      this.country = null;
      this.city = null;
    },
    changeView(view) {
      this.numberOfShownEvents = 30;
      this.view = view;
    },
    toggleSelectedTourTypes(type) {
      if (this.enabledTourTypes.includes(type)) {
        this.enabledTourTypes = this.enabledTourTypes.filter(
          (tourType) => tourType.type != type.type
        );
      } else {
        this.enabledTourTypes.push(type);
      }
    },
    toggleSelectedPDGATypes(type) {
      if (this.enabledpdgaTypes.includes(type)) {
        this.enabledpdgaTypes = this.enabledpdgaTypes.filter(
          (pdgaType) => pdgaType.type != type.type
        );
      } else {
        this.enabledpdgaTypes.push(type);
      }
    },
    collapse() {
      this.$nextTick(() => {
        this.showFilters = !this.showFilters;
      });
    },
    hideFilters() {
      this.$nextTick(() => {
        this.showFilters = false;
      });
    },
    selectedDateSelected(date) {
      if (moment(this.dayViewDay).isSame(date, "day")) return true;
      return false;
    },
    selectedWeekSelected(week) {
      if (moment(this.weekViewWeek).isSame(week.startDate, "isoweek"))
        return true;
      return false;
    },
    selectedMonthSelected(month) {
      if (moment(this.monthViewMonth).isSame(month.startDate, "month"))
        return true;
      return false;
    },
    forwardOneDay() {
      this.animationDirection = "forward";

      let dateToAdd = moment(
        this.listOfViewedDays[this.listOfViewedDays.length - 1]
      )
        .add(1, "days")
        .toISOString();
      this.listOfViewedDays.push(dateToAdd);
      this.listOfViewedDays.splice(0, 1);
    },
    forwardOneWeek() {
      this.animationDirection = "forward";

      let dateToAdd = {
        startDate: moment(
          this.listOfViewedWeeks[this.listOfViewedWeeks.length - 1].startDate
        )
          .add(1, "weeks")
          .toISOString(),
        endDate: moment(
          this.listOfViewedWeeks[this.listOfViewedWeeks.length - 1].endDate
        )
          .add(1, "weeks")
          .toISOString(),
      };

      this.listOfViewedWeeks.push(dateToAdd);
      this.listOfViewedWeeks.splice(0, 1);
    },
    forwardOneMonth() {
      this.animationDirection = "forward";

      let dateToAdd = {
        startDate: moment(
          this.listOfViewedMonths[this.listOfViewedMonths.length - 1].startDate
        )
          .add(1, "months")
          .startOf("month")
          .toISOString(),
        endDate: moment(
          this.listOfViewedMonths[this.listOfViewedMonths.length - 1].endDate
        )
          .add(1, "months")
          .endOf("month")
          .toISOString(),
        month: moment(
          this.listOfViewedMonths[this.listOfViewedMonths.length - 1].startDate
        )
          .add(1, "months")
          .format("MMM"),
        year: moment(
          this.listOfViewedMonths[this.listOfViewedMonths.length - 1].startDate
        )
          .add(1, "months")
          .format("YYYY"),
      };

      this.listOfViewedMonths.push(dateToAdd);
      this.listOfViewedMonths.splice(0, 1);
    },
    backOneDay() {
      this.animationDirection = "backward";

      let dateToAdd = moment(this.listOfViewedDays[0])
        .subtract(1, "days")
        .toISOString();

      this.listOfViewedDays.splice(0, 0, dateToAdd);
      this.listOfViewedDays.splice(this.listOfViewedDays.length - 1, 1);
    },
    backOneWeek() {
      this.animationDirection = "backward";

      let dateToAdd = {
        startDate: moment(this.listOfViewedWeeks[0].startDate)
          .subtract(1, "weeks")
          .toISOString(),
        endDate: moment(this.listOfViewedWeeks[0].endDate)
          .subtract(1, "weeks")

          .toISOString(),
      };

      this.listOfViewedWeeks.splice(0, 0, dateToAdd);
      this.listOfViewedWeeks.splice(this.listOfViewedWeeks.length - 1, 1);
    },
    backOneMonth() {
      this.animationDirection = "backward";

      let dateToAdd = {
        startDate: moment(this.listOfViewedMonths[0].startDate)
          .subtract(1, "months")
          .startOf("month")
          .toISOString(),
        endDate: moment(this.listOfViewedMonths[0].endDate)
          .subtract(1, "months")
          .endOf("month")
          .toISOString(),
        month: moment(this.listOfViewedMonths[0].endDate)
          .subtract(1, "months")

          .format("MMM"),
        year: moment(this.listOfViewedMonths[0].endDate)
          .subtract(1, "months")

          .format("YYYY"),
      };

      this.listOfViewedMonths.splice(0, 0, dateToAdd);
      this.listOfViewedMonths.splice(this.listOfViewedMonths.length - 1, 1);
    },
    daysViewFormatted(day) {
      let dayObject = {
        day: moment(day).format("D"),
        date: moment(day).format("ddd"),
        month: moment(day).format("MMM"),
      };
      return dayObject;
    },
    weeksViewFormatted(week) {
      return moment(week).format("D MMM");
    },
    weeksViewFormattedStart(week) {
      return moment(week).format("D MMM");
    },
    weeksViewFormattedEnd(week) {
      return moment(week).endOf("isoweek").format("D MMM");
    },
    monthViewFormatted(date) {
      return moment(date).format("D");
    },
    monthViewFormattedMessage(date) {
      return moment(date).format("MMM YYYY");
    },
    populateInitialDateViews() {
      let dayDate = moment().subtract(2, "days");
      const numberOfDaysToRender = this.deviceWidth < 1200 ? 5 : 7;
      const numberOfWeeksToRender = this.deviceWidth < 1200 ? 4 : 6;
      const numberOfMonthsToRender = this.deviceWidth < 1200 ? 4 : 6;
      let weekDate = moment().subtract(1, "weeks").startOf("isoweek");
      let monthDate = moment().subtract(1, "months").startOf("month");
      let daysList = [];
      let weekList = [];
      let monthList = [];

      if (this.$route.query.view == "DAY" && this.$route.query.startDate) {
        dayDate = moment(this.$route.query.startDate).subtract(2, "days");
        this.dayViewDay = moment(this.$route.query.startDate).toISOString();
        this.view = "DAY";
      } else if (
        this.$route.query.view == "WEEK" &&
        this.$route.query.startDate
      ) {
        weekDate = moment(this.$route.query.startDate)
          .subtract(1, "weeks")
          .startOf("isoweek");
        this.weekViewWeek = moment(this.$route.query.startDate).toISOString();
        this.view = "WEEK";
      } else if (
        this.$route.query.view == "MONTH" &&
        this.$route.query.startDate
      ) {
        monthDate = moment(this.$route.query.startDate)
          .subtract(1, "months")
          .startOf("month");
        this.monthViewMonth = moment(this.$route.query.startDate).toISOString();

        this.view = "MONTH";
      } else if (
        this.$route.query.view == "ALL_TIME" &&
        (this.$route.query.startDate || this.$route.query.endDate)
      ) {
        this.allTimeDateRangeStart.date = this.$route.query.startDate
          ? moment(this.$route.query.startDate)
          : null;
        this.allTimeDateRangeEnd.date = this.$route.query.endDate
          ? moment(this.$route.query.endDate)
          : null;

        this.view = "ALL_TIME";
      } else if (this.$route.query.view == "ALL_TIME") {
        this.allTimeDateRangeStart.date = null;
        this.allTimeDateRangeEnd.date = null;

        this.view = "ALL_TIME";
      } else {
        this.view = "DAY";
      }

      for (let index = 0; index < numberOfDaysToRender; index++) {
        daysList.push(moment(dayDate.add(1, "days")).toISOString());
      }

      for (let index = 0; index < numberOfWeeksToRender; index++) {
        let week = {
          startDate: weekDate.toISOString(),
          endDate: weekDate.endOf("isoweek").toISOString(),
        };
        weekList.push(week);
        weekDate.add(1, "days");
      }

      for (let index = 0; index < numberOfMonthsToRender; index++) {
        let month = {
          startDate: monthDate.toISOString(),
          endDate: monthDate.endOf("month").toISOString(),
          month: monthDate.format("MMM"),
          year: monthDate.format("YYYY"),
        };
        monthList.push(month);
        monthDate.add(1, "days");
      }

      this.listOfViewedWeeks = weekList;
      this.listOfViewedDays = daysList;
      this.listOfViewedMonths = monthList;
    },
    unSortedEventsList() {
      let eventList = [];
      let cities = [];
      let countries = [];

      this.tours.forEach((tour) => {
        tour.events.forEach((event, index) => {
          event.tourName = tour.name;
          event.tourId = tour.id;
          event.eventNumber = index + 1;
          event.maxEvents = tour.eventsCount;
          event.tourType = tour.type;
          event.oneOffEvent = tour.events.length == 1 ? true : false;
          event.registrationMethod = tour.registrationMethod;
          event.lat = null;
          event.lng = null;

          if (event.tourType == null) {
            event.tourType = {
              name: "Other",
              type: "Other",
            };
          }

          if (event.pdgaType == null) {
            event.pdgaType = {
              name: "Unsanctioned",
              type: "Unsanctioned",
            };
          }

          if (event.city) {
            cities.push(event.city);
            countries.push(event.city.country);
            event.cityName = event.city.name;
            event.country = event.city.country;
            event.lat = event.city.geolocation.lat;
            event.lng = event.city.geolocation.lng;
            event.cityId = event.city.id;
          }

          eventList.push(event);
        });
      });

      cities = uniqBy(cities, "name");
      this.citiesList.push(...cities);
      this.citiesList = uniqBy(this.citiesList, "name");
      this.citiesList = sortBy(this.citiesList, "name");

      countries = uniqWith(countries, isEqual);
      this.countriesList.push(...countries);
      this.countriesList = uniqWith(this.countriesList, isEqual);
      this.countriesList = sortBy(this.countriesList);

      this.unsortedEventList = eventList;
      this.loaded = true;
    },
    async loadOngoingTours() {
      let allTours = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {

            pdgaEventTypes{
                name
                type
            }
            tourTypes{
                name
                type
            }
            tours(filter: {operator: OR, query: [{status: ONGOING},{status: CLOSED}]}) {
              id
              name
              eventsCount
              status
              registrationMethod
              type{
                name
                type
              }
              events {
                pdgaType{
                  name
                  type
                }
                id
                isUserFavourite
                startDate
                endDate
                status
                name
                location
                isRegistrationOpen
                registrationOpenAt
                city {
                  id
                  name
                  country
                  county
                  geolocation {
                    lat
                    lng
                  }
                }
              }
            }
          }
        `,
        },
      });

      this.tours = allTours.data.data.tours;
      this.pdgaTypes.push(...allTours.data.data.pdgaEventTypes);
      this.tourTypes.push(...allTours.data.data.tourTypes);
      this.unSortedEventsList();
    },
    async loadPastTours() {
      let allTours = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            tours(filter: {operator: AND, query: [{status: COMPLETED}]}) {
              id
              name
              eventsCount
              status
              registrationMethod
              type{
                name
                type
              }
              events {
                pdgaType{
                  name
                  type
                }
                id
                isUserFavourite
                startDate
                endDate
                status
                name
                location
                isRegistrationOpen
                registrationOpenAt
                city {
                  id
                  name
                  country
                  county
                  geolocation {
                    lat
                    lng
                  }
                }
              }
            }
          }
        `,
        },
      });

      this.tours.push(...allTours.data.data.tours);
      this.unSortedEventsList();
      this.alltoursLoaded = true;
    },
  },
  async beforeMount() {
    this.$store.dispatch("loadTemporaryCloseableElements");

    if (this.$route.query.search) {
      this.textSearch = this.$route.query.search;
      this.searchString = this.$route.query.search;
    }
    if (this.$route.query.finished == "yes") {
      this.includeFinishedEvents = true;
    }
    this.populateInitialDateViews();
    this.getContent();
    await this.loadOngoingTours();

    if (this.$route.query.view) {
      this.view = this.$route.query.view;
    }
  },
  async mounted() {
    try {
      this.usersPosition = await this.getLocation();
      this.sortBy = "DISTANCE";
    } catch (err) {
      if (err.code == 1) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services disabled",
          type: "error",
        });
      }
    }
  },
  created() {},
  destroyed() {},
};
</script>
<style lang="scss">
.featured-events {
  .VueCarousel-pagination {
    background-color: white;
    .VueCarousel-dot {
      border-color: $midnight !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.featured-events {
  margin: 18px 0 0px 0;
  padding: 0 18px;

  .featured-title {
    margin-bottom: 10px;
    font-size: 14px;
  }
}
.featured-event {
  border: 1px solid $sleet;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  justify-content: space-between;

  img {
    max-height: 36px;
    width: auto;
  }

  p {
    font-size: 14px;
    margin: 0;

    &.small {
      font-size: 12px;
      margin-top: 2px;
    }
  }
}
main {
  margin-bottom: 32px;
  width: 100%;
}

.new-feature-push {
  margin-bottom: 18px;
  background: #fff0cc;
  border-radius: 6px;
  padding: 12px 14px;
  position: relative;

  .content {
    padding-right: 24px;
    svg {
      width: auto;
      height: 14px;
      margin-bottom: 4px;
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }
  .close-push {
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 12px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.empty-search-messages {
  margin: 0px auto;
  padding: 0;
  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    color: $strom;

    .ocean {
      color: $ocean;
      cursor: pointer;
    }
  }
}

.search {
  margin: 25px 15px 0 15px;
  position: relative;

  .search-icon {
    position: absolute;
    left: 18px;
    top: 50%;

    transform: translateY(-50%);
  }
  .clear {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all ease 0.3s;
    svg {
      width: 12px;
      height: 12px;
    }
    &.active {
      opacity: 1;
      cursor: pointer;
    }
  }

  .search-input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    width: 100%;
    background-color: $mist;
    color: $midnight;
    border-radius: 50px;
    height: 48px;
    font-size: 16px;
    padding-left: 50px;
    padding-right: 40px;
    @include Gilroy-Medium;
    transition: all ease 0.3s;
    border: 2px solid $mist;

    &.active {
      border: 2px solid $ocean;
    }

    &::placeholder {
      color: $blizzard;
      @include Gilroy-Medium;
    }

    &:hover {
      border: 2px solid $blizzard;
    }

    &:focus {
      transition: all ease 0.3s;
      outline: none;
      border: 2px solid $ocean;
    }
  }
}
.upcoming-finished {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 30px 15px;
  margin-top: 30px;

  div {
    font-size: 14px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    transition: all ease 0.3s;
    cursor: pointer;
    background-color: $mist;

    &:hover {
      background-color: $fog;
    }

    &.active {
      background-color: $ocean;
      color: white;
      transition: all ease 0.3s;
      cursor: default;
      @include Gilroy-Bold;
    }
  }
}
.events-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 15px;

  main {
    z-index: 1;
  }

  .found-events-count {
    font-size: 16px;
    color: $strom;
    margin-bottom: 24px;
  }

  .event {
    width: 100%;
    min-height: 140px;
    border: 1px solid $fog;
    background-color: $snow;
    margin-bottom: 20px;
    padding: 15px;

    p {
      @include Gilroy-Bold;
    }
  }

  .show-more {
    color: $ocean;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .back-to-top {
    margin-top: 48px;
    color: $ocean;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
}
.view-picker {
  padding: 30px 15px 20px 15px;
  border-top: 1px solid $fog;

  ul {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    z-index: 300;

    li {
      font-size: 14px;
      height: 28px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      transition: all ease 0.3s;
      cursor: pointer;
      background-color: $mist;
      user-select: none;

      &:hover {
        background-color: $fog;
      }

      &.active {
        background-color: $ocean;
        color: white;
        transition: all ease 0.3s;
        cursor: default;
        @include Gilroy-Bold;
      }
    }
  }
}

.tags-move {
  transition: transform 1s;
}
.tags-active {
  position: absolute;
}
.tags-leave-active,
.tags-enter-active {
  transition: all ease-in-out 0.3s;
}
.tags-leave {
  max-width: 220px;
  max-height: 0px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.tags-leave-to {
  transform: scale(0);
  transform-origin: left;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 0px;
  max-height: 0px;
}
.tags-enter {
  transform: scale(0);
  transform-origin: right;
  overflow: hidden;
  white-space: nowrap;
  border: 0px solid grey;
  max-width: 220px;
  opacity: 0;
}
.tags-enter-to {
  transform: scale(0);
  transform-origin: right;
  overflow: hidden;
  white-space: nowrap;
  border: 0px solid grey;
  max-width: 220px;

  opacity: 0;
}

.icon {
  max-width: 0px;
  transition: all ease 0.3s;
  overflow: hidden;
  opacity: 0;
  width: 16px;

  &.left {
    margin-right: 8px;
    transform: rotate(45deg);
  }
  &.right {
    margin-left: 8px;
  }

  &.active {
    max-width: 10px;
    transition-delay: 0;
    opacity: 1;
  }
}

.filters {
  padding: 0;
  margin: 0px 0 0px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .active-filters-wrapper {
    width: 100%;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    transition: all ease 0.3s;
    border-top: 1px solid white;
    cursor: pointer;
    margin: 24px 15px 24px 15px;
    user-select: none;

    .filter-button {
      background-color: $mist;
      padding: 7px 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all ease 0.3s;
      position: relative;
      width: 120px;

      span {
        @include Gilroy-Bold;
      }

      &.active {
        background: $ocean;
        color: white;

        &:hover {
          background: $oceanshade;
        }
      }

      &.left {
        svg {
          width: 14px;
          height: 10px;
          margin-bottom: 1px;
          margin-right: 0px;
        }
      }

      &:hover {
        background-color: $fog;
      }

      &.right {
        width: 156px;
        justify-content: space-between;

        span {
          @include Gilroy-Regular;
        }

        span {
          margin-right: 6px;
          margin-left: 0px;
        }

        .dropdown {
          position: absolute;
          right: 0;
          top: 40px;
          width: 100%;
          z-index: 30;

          ul {
            border: 1px solid $blizzard;
            border-radius: 6px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              height: 48px;
              display: flex;
              align-items: center;
              padding-left: 15px;
              font-size: 16px;
              background-color: white;
              border-radius: 6px;

              &.disabled {
                color: $sleet;
                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: $mist;
              }
            }
          }
        }
      }
      span {
        margin-left: 6px;
        font-size: 14px;
        b {
          @include Gilroy-Bold;
          display: inline;
        }
      }
    }

    .sort {
      margin: 0;
    }
  }

  .active-filters {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 15px;
    transition: all ease 0.3s;
    max-height: 300px;
    padding-bottom: 0px;

    li {
      padding: 6px 12px;
      border: 1px solid $twilight;
      border-radius: 16px;
      margin-right: 12px;
      background-color: $twilight;
      color: white;
      @include Gilroy-Bold;
      font-size: 15px;
      transition: all ease 0.3s;
      margin-bottom: 12px;
      max-height: 32px;
      overflow: hidden;
      display: flex;
      align-items: center;
      display: flex;
      cursor: pointer;

      svg {
        max-width: 10px;
        margin-left: 10px;
      }

      &.clear-filters {
        background: none;
        color: $twilight;
        @include Gilroy-Regular;
        font-size: 15px;
        border: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}
.filter-open {
  background-color: $snow;
  margin: 0 15px;
  padding: 20px 20px;
  border-radius: 6px;
  border: 2px solid $ocean;
  ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
      padding: 6px 12px;
      border: 1px solid $blizzard;
      color: $strom;
      border-radius: 16px;
      margin-right: 12px;
      transition: all ease 0.3s;
      margin-bottom: 12px;
      @include Gilroy-Bold;
      font-size: 15px;
      display: flex;
      align-items: center;
      max-width: 220px;
      max-height: 32px;
      cursor: pointer;

      &.active {
        background-color: $twilight;
        border: 1px solid $twilight;
        color: white;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    .clear {
      color: $twilight;
      cursor: pointer;
    }
    .hide {
      color: $ocean;
      cursor: pointer;
    }
  }
  .nearby {
    @include Gilroy-Bold;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .items-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $sleet;
    margin-bottom: 24px;
    span {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: $blizzard;
      border: 1px solid $blizzard;
      @include Gilroy-Bold;
      height: 32px;
      transition: all ease 0.3s;

      &.active {
        border: 1px solid $twilight;
        color: white;
        background-color: $twilight;
      }
    }
  }
  .tour-types {
    @include Gilroy-Bold;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .pdga-tiers {
    @include Gilroy-Bold;
    font-size: 14px;
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $sleet;
    margin-top: 15px;
  }
  .location {
    @include Gilroy-Bold;
    font-size: 14px;
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $sleet;
    margin-top: 15px;
  }
}

.positioned {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 300px;
}

.event {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
  height: 30px;
}
.event-list-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.event-list-leave-to {
  opacity: 0;
  transform: 0s ease;
}
.event-list-leave-active {
  position: absolute;
  display: block;
  height: auto;
}

.event-list-move {
  transform: 1s ease;
}
.all-time-view {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 15px;

  .start {
    width: 100%;
    margin-bottom: 18px;
    p {
      margin-bottom: 12px;
    }
  }
  .end {
    p {
      margin-bottom: 12px;
    }
    width: 100%;
  }
}
.day-view {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow: hidden;

  .back,
  .next {
    svg {
      width: 12px;
      height: 24px;
      margin-bottom: 11px;
    }
  }

  p {
    flex: 8;
    position: relative;

    user-select: none;
    div {
      width: 20%;
    }
  }
  .back {
    cursor: pointer;
  }
  .next {
    cursor: pointer;
  }
  > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
.week-view {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow: hidden;
  .back,
  .next {
    svg {
      width: 12px;
      height: 24px;
      margin-bottom: 11px;
    }
  }
  p {
    flex: 8;
    position: relative;

    user-select: none;
    div {
      width: 25%;
    }
  }
  .back {
    cursor: pointer;
  }
  .next {
    cursor: pointer;
  }
  > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
.month-view {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow: hidden;
  .back,
  .next {
    svg {
      width: 12px;
      height: 24px;
      margin-bottom: 11px;
    }
  }
  p {
    flex: 8;
    position: relative;

    user-select: none;
    div {
      width: 25%;
    }
  }
  .back {
    cursor: pointer;
  }
  .next {
    cursor: pointer;
  }
  > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.list-item {
  transition: all 0.5s;
  text-align: center;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  flex-wrap: nowrap;
  text-transform: uppercase;
  transition: all ease 0.3s;

  span {
    @include Gilroy-Bold;
  }
  &:hover {
    &::after {
      content: "";
      height: 4px;
      border-radius: 2px;
      background-color: $mist;
      width: 80%;
      position: absolute;
      bottom: -5px;
      left: 10%;
      transition: all ease 0.3s;
    }
  }

  &.selected {
    color: $twilight;
    cursor: default;
    &::after {
      content: "";
      height: 4px;
      border-radius: 2px;
      background-color: $twilight;
      width: 80%;
      position: absolute;
      bottom: -5px;
      left: 10%;
    }
  }

  .date {
    font-size: 12px;
  }
  .day,
  .month-large {
    font-size: 24px;
  }
  .month {
    font-size: 12px;
  }
  .start {
    font-size: 14px;
  }
  .to {
    font-size: 12px;
    text-transform: none;
  }
  .end {
    font-size: 14px;
  }
  .between {
    font-size: 12px;
  }
  .year {
    font-size: 12px;
  }
}

.forward {
  .list-leave-to {
    opacity: 0;
    transform: translateX(-100%) scale(0.3);
  }
  .list-enter {
    opacity: 0;
    transform: translateX(100%) scale(0.3);
  }
}
.backward {
  .list-leave-to {
    opacity: 0;
    transform: translateX(0) scale(0.3);
  }
  .list-enter {
    opacity: 0;
    transform: translateX(-100%) scale(0.3);
  }
}

.list-leave-active {
  position: absolute;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .events-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .found-events-count {
      width: 100%;
    }
    .event-card {
      width: calc(50% - 30px);
      margin: 20px 15px;
    }
  }
}

@media (min-width: 1200px) {
  .new-feature-push {
    margin-top: 32px;
    width: 100%;
    .close-push {
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      svg {
        width: 14px;
        height: 14px;
      }
    }
    .content {
      p {
        font-size: 0.875rem;
      }

      svg {
        width: auto;
        height: 16px;
        margin-bottom: 4px;
      }
    }
  }
  .featured-events {
    max-width: 1200px;
    margin: 42px auto 0 auto;

    .featured-title {
      padding-left: 12px;
      margin-bottom: 18px;
      font-size: 14px;
    }
  }
  .featured-event {
    margin: 0 12px;

    &:nth-child(3) {
      margin-right: 0;
    }
  }
  .beta-banner {
    padding: 0px 15px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    .content {
      p {
        font-size: 18px;
        svg {
          height: 20px;
        }
      }
    }
    .close-push {
      position: relative;
      top: unset;
      right: unset;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .empty-search-messages {
    margin: 80px auto;
    padding: 0;
    p {
      text-align: center;
      font-size: 24px;
      margin-bottom: 10px;
      color: $strom;
    }
  }
  main {
    .search-container {
      margin: 0 auto;
      width: 1200px;
      margin-top: 64px;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
    }
    .search {
      width: 75%;
      margin: 0;
      margin-right: 5%;
    }

    .upcoming-finished {
      width: 20%;
      margin: 0;
      border: none;
      padding: 0;
      justify-content: flex-start;

      div {
        margin: 0 20px 0 0;
        font-size: 18px;
        height: 36px;
        border-radius: 18px;
      }
    }

    .view-picker {
      margin-top: 45px;
      padding: 0;
      border: none;
      ul {
        li {
          font-size: 18px;

          height: 36px;
          border-radius: 18px;
          margin-right: 25px;
          padding: 0 20px;
        }
      }
    }

    .day-view {
      margin-top: 30px;
      .back,
      .next {
        flex: 0.5;
        height: 50px;
        display: flex;
        align-items: center;

        svg {
          width: auto;
          height: auto;
        }
      }

      p {
        div {
          width: 14.285%;

          .date {
            font-size: 16px;
          }
          .day {
            font-size: 40px;
            padding: 10px 0;
          }
          .month {
            font-size: 16px;
          }
          &:hover {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }
          &.selected {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }
        }
      }
    }
    .week-view {
      margin-top: 30px;
      .back,
      .next {
        flex: 0.5;
        height: 50px;
        display: flex;
        align-items: center;
        svg {
          width: auto;
          height: auto;
        }
      }

      p {
        div {
          width: 16.66%;

          .start {
            font-size: 20px;
          }
          .to {
            font-size: 18px;
            padding: 10px 0;
          }
          .end {
            font-size: 20px;
          }

          &.selected {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }

          &:hover {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }
        }
      }
    }
    .month-view {
      margin-top: 30px;
      .back,
      .next {
        flex: 0.5;
        height: 50px;
        display: flex;
        align-items: center;
        svg {
          width: auto;
          height: auto;
        }
      }

      p {
        div {
          width: 16.66%;

          .between {
            font-size: 16px;
          }
          .month-large {
            font-size: 40px;
            padding: 10px 0;
          }
          .year {
            font-size: 16px;
          }

          &.selected {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }
          &:hover {
            &::after {
              width: 50%;
              left: 25%;
              bottom: -10px;
            }
          }
        }
      }
    }

    .all-time-view {
      margin-top: 30px;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: row;

      .start,
      .end {
        width: 35%;
        margin-right: 15px;
      }
    }
    .filters {
      width: 100%;
      padding: 0;
      border: none;
      margin-top: 35px;
      margin-bottom: 0;

      .active-filters-wrapper {
        width: calc(100% - 180px);
      }
      .header {
        user-select: none;
        margin: 0;
        .filter-button {
          padding: 9px 20px;
          border-radius: 19px;
          margin-bottom: 12px;
          border-radius: 6px;
          width: 163px;

          &.left {
            svg {
              height: 12px;
              width: 16px;
              margin-right: 3px;
            }
          }

          &.right {
            width: 210px;

            .dropdown {
              top: 50px;
            }
          }

          span {
            font-size: 18px;
          }
        }
      }

      .filter-open {
        margin: 15px 0 0 0;
        padding: 35px;
        .tour-types {
          font-size: 18px;
          margin-bottom: 24px;
        }
        .pdga-tiers {
          font-size: 18px;
          margin-bottom: 24px;
          border: none;
          padding: 0;
          margin-top: 36px;
        }
        .location {
          font-size: 18px;
          margin-bottom: 24px;
          border: none;
          padding: 0;
          margin-top: 36px;
        }

        select {
          width: 30%;
          margin-right: 24px;
        }

        ul {
          li {
            height: 36px;
            max-height: 36px;
            border-radius: 18px;
            font-size: 18px;
          }
        }

        .footer {
          .clear {
            font-size: 18px;
            margin-bottom: 0;
          }
          .hide {
            font-size: 18px;
            margin-bottom: 0;
          }
        }
      }

      .active-filters {
        li {
          padding: 6px 12px;
          border: 1px solid $twilight;
          border-radius: 18px;
          margin-right: 12px;
          background-color: $twilight;
          color: white;
          @include Gilroy-Bold;
          font-size: 18px;
          transition: all ease 0.3s;
          margin-bottom: 12px;
          max-height: 36px;
          overflow: hidden;
          display: flex;
          align-items: center;
          display: flex;
          cursor: pointer;

          &.clear-filters {
            font-size: 18px;
          }
        }
      }
    }

    .events-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      width: 100%;

      .found-events-count {
        padding-left: 15px;
        margin-top: 24px;
        font-size: 18px;
        margin-bottom: 0;
      }
      .event-card {
        width: calc(33.3% - 30px);
        margin: 20px 15px;
      }

      .show-more,
      .back-to-top {
        width: 100%;
        font-size: 18px;
      }
    }
  }
}
</style>

