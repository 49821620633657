<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    :class="[direction, color]"
  >
    <g transform="translate(16 12) rotate(180)">
      <path
        class="a"
        d="M566.111,1212H551.889a1.007,1.007,0,0,1,0-2h14.222a1.007,1.007,0,0,1,0,2ZM564,1216a.928.928,0,0,0-.833-1h-8.334a1.017,1.017,0,0,0,0,2h8.334A.928.928,0,0,0,564,1216Zm-2,5c0-.553-.335-1-.75-1h-4.5c-.414,0-.75.447-.75,1s.336,1,.75,1h4.5C561.665,1222,562,1221.553,562,1221Z"
        transform="translate(-551 -1210)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Filters",
  props: ["direction", "color"],
};
</script>

<style lang="scss" scoped>
.white {
  .a {
    fill: white;
  }
}
.a {
  fill: #2d323c;
  transition: all ease 0.3s;
}

svg {
  transition: all ease 0.3s;
}
.up {
  transform: scaleY(-1);
}
</style>