<template>
  <main class="event-card">
    <section class="event-info">
      <h3>
        {{ eventData.name }}
      </h3>
      <span class="star" @click="toggleEventFavourite(eventData.id)">
        <Star :type="eventData.isUserFavourite ? 'filled' : 'hollow'" />
      </span>

      <p v-if="eventData.cityName != null">
        {{ eventData.cityName }}, {{ eventData.country }}
      </p>
      <h5 :class="{ 'single-event': eventData.oneOffEvent }">
        {{ eventDateFormatted(eventData.startDate, eventData.endDate) }}
      </h5>
      <p class="tour-name" v-if="eventData.oneOffEvent == false">
        Tour: <b>{{ eventData.tourName }}</b>
      </p>
    </section>

    <section class="tags">
      <ul>
        <li class="card-label sunset tour-type">
          {{ eventData.tourType.name }}
        </li>
        <li
          class="card-label ocean"
          v-if="eventData.pdgaType.type != 'Unsanctioned'"
        >
          {{ eventData.pdgaType.name }}
        </li>
        <li class="card-label sunrise distance" v-if="distanceInKm != null">
          {{ distanceInKm }} km
        </li>
      </ul>
    </section>
    <section class="status">
      <div
        class="event-finished"
        v-if="
          eventData.status == 'COMPLETED' || isDateYesterday(eventData.endDate)
        "
      >
        <span class="status-label strom">Finished</span>
      </div>
      <div
        class="event-ongoing"
        v-else-if="showEventIsLive(eventData.startDate, eventData.status)"
      >
        <span class="status-label grass">In progress</span>
      </div>

      <div
        class="registration-closed"
        v-else-if="
          eventData.isRegistrationOpen == false &&
          (eventData.registrationOpenAt == null ||
            isDateInThePast(eventData.registrationOpenAt))
        "
      >
        <Off />Registration closed
      </div>
      <div
        class="registration-closed"
        v-else-if="
          eventData.isRegistrationOpen == false &&
          isDateInThePast(eventData.registrationOpenAt) == false
        "
      >
        <RegistrationOpen color="sunrise" /> Registration opens
        {{ dateformatter(eventData.registrationOpenAt) }}
      </div>
      <div class="registration-open" v-else-if="eventData.isRegistrationOpen">
        <RegistrationOpen color="" />Registration open
      </div>

      <span class="toggle" @click="toggleEventDetails()">
        <Chevron2 :direction="showDetails ? 'up' : 'down'" />
      </span>

      <slide-up-down :active="showDetails" :duration="250" class="w-100">
        <template v-if="eventDetails != null">
          <div class="event-details">
            <ul>
              <li>
                <ConfirmedPlayers />{{ eventDetails.playersCount }} player{{
                  singularPluralFormat(eventDetails.playersCount)
                }}
                confirmed
              </li>
              <li>
                <WaitingList />{{ waitingListLength }} player{{
                  singularPluralFormat(waitingListLength)
                }}
                waiting
              </li>
              <li>
                <SpotsLeft :color="spotsLeft == 0 ? 'dusk' : 'grass'" />{{
                  spotsLeft
                }}
                spot{{
                  singularPluralFormat(
                    eventDetails.maxSpots - eventDetails.playersCount
                  )
                }}
                left
              </li>
            </ul>
          </div>
          <div class="check-in" v-if="showCheckInSection">
            <p><CheckIn />Check in enabled</p>
            <p class="small">
              Opens
              <b>{{ checkInTimeFormatter(eventDetails.checkInStartsAt) }}</b>
              at
              <b>{{ timeOnly(eventDetails.checkInStartsAt) }}</b>
            </p>
            <p class="small">
              Closes
              <b>{{ checkInDateFormatter(eventDetails.checkInEndsAt) }}</b>
              at
              <b>{{ timeOnly(eventDetails.checkInEndsAt) }}</b>
            </p>
          </div>
          <div class="features-status">
            <span
              ><p v-if="eventDetails.isVerifyMode"><On />Verifier enabled</p>
              <p v-else><Off />Verifier disabled</p></span
            >
            <span
              ><p v-if="eventDetails.isResultSigningEnabled">
                <On />Signature enabled
              </p>
              <p v-else><Off />Signature disabled</p></span
            >
          </div>
          <div
            class="course-details"
            v-for="course in playedCourses"
            :key="course.layout.id"
          >
            <div class="course">
              <p>Course:</p>
              <b>{{ course.layout.course.name }}</b>
            </div>
            <div class="layout">
              <p>Layout:</p>
              <b>{{ course.layout.name }}</b>
              <span class="custom-tag" v-if="course.layout.type == 'CUSTOM'"
                >Custom</span
              >
              <Verified v-else />
            </div>
            <div class="tags">
              <ul>
                <li class="card-label ocean holes">
                  {{ course.holes.length }} Holes
                </li>
                <li class="card-label strom">
                  Par {{ layoutPar(course.holes) }}
                </li>
              </ul>
            </div>
          </div>
          <div class="round-pools-details">
            <ul>
              <li
                v-for="(round, roundIndex) in eventDetails.rounds"
                :key="round.id"
              >
                <template v-for="(pool, poolIndex) in round.pools">
                  <p :key="pool.id" v-if="round.pools.length == 1">
                    Round {{ roundIndex + 1 }}
                    <span>(Starts {{ poolStartTime(pool.date) }})</span>
                  </p>
                  <p :key="pool.id" v-else>
                    Round {{ roundIndex + 1 }} - Pool
                    {{ $store.state.poolConversion[poolIndex] }}
                    <span>(Starts {{ poolStartTime(pool.date) }})</span>
                  </p>
                </template>
              </li>
            </ul>
          </div>
        </template>
      </slide-up-down>
    </section>
    <section class="footer">
      <router-link class="view" :to="goToEvent()">View event</router-link>
    </section>
  </main>
</template>

<script>
import { uniqBy } from "lodash";
import locationMixin from "@/mixins/locationMixin.js";
import moment from "moment";
import CheckIn from "@/assets/icons/CheckIn";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import SlideUpDown from "vue-slide-up-down";
import Chevron2 from "@/assets/icons/Chevron2";
import Verified from "@/assets/icons/Verified";
import SpotsLeft from "@/assets/icons/SpotsLeft";
import Star from "@/assets/icons/Star";

export default {
  name: "TheFindEventsEventCard",
  props: ["eventData", "usersPosition"],
  components: {
    Off,
    RegistrationOpen,
    On,
    SlideUpDown,
    WaitingList,
    ConfirmedPlayers,
    CheckIn,
    Chevron2,
    Verified,
    SpotsLeft,
    Star,
  },
  mixins: [locationMixin],
  data() {
    return {
      eventDetails: null,
      showDetails: false,
    };
  },
  computed: {
    spotsLeft() {
      if (this.eventDetails.maxSpots - this.eventDetails.playersCount <= 0)
        return 0;
      else {
        return this.eventDetails.maxSpots - this.eventDetails.playersCount;
      }
    },
    showCheckInSection() {
      let bool = true;
      if (this.eventDetails.checkInStartsAt == null) bool = false;
      if (moment(this.eventData.startDate).isBefore()) bool = false;

      return bool;
    },
    playedCourses() {
      let courseList = [];

      this.eventDetails.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          courseList.push(pool.layoutVersion);
        });
      });

      return uniqBy(courseList, function (e) {
        return e.layout.id;
      });
    },
    userId() {
      return this.$store.getters.userInfo.sessionInfo.userId;
    },
    userHasTourPass() {
      return this.userTourPasses.includes(this.eventData.tourId);
    },
    waitingListLength() {
      if (this.eventDetails == null) {
        return null;
      }
      return this.eventDetails.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
    distanceInKm() {
      let distance = 0;
      if (this.usersPosition != null) {
        if (this.eventData.lat && this.eventData.lng) {
          distance = this.getDistanceInKM(
            this.eventData.lat,
            this.eventData.lng,
            this.usersPosition.lat,
            this.usersPosition.lng
          );
        } else {
          distance = null;
        }
      } else {
        distance = null;
      }
      return distance == null ? null : Math.round(distance * 10) / 10;
    },
  },
  methods: {
    goToEvent() {
      let name = "public-event-results";

      if (this.eventData.status == "CLOSED") name = "public-event-players";
      if (this.eventData.isRegistrationOpen) name = "public-event-players";
      if (
        this.eventData.status == "ONGOING" &&
        this.eventData.isRegistrationOpen == false
      )
        name = "public-event-rounds";

      return {
        name,
        params: { eventId: this.eventData.id },
      };
    },
    singularPluralFormat(count) {
      if (count != 1) return "s";
      else return "";
    },
    layoutPar(holes) {
      let par = 0;

      holes.forEach((hole) => (par += hole.par));

      return par;
    },
    timeOnly(date) {
      return moment(date).format("HH:mm");
    },
    checkInDateFormatter(date) {
      return moment(date).format("D MMM");
    },
    checkInTimeFormatter(date) {
      if (moment(date).isSame(moment(), "day")) {
        return "TODAY";
      }
      if (moment(date).add(1, "days").isSame(moment(), "day")) {
        return "yesterday";
      }
      if (moment(date).subtract(1, "days").isSame(moment(), "day")) {
        return "TOMORROW";
      }

      return moment(date).format("D MMM");
    },
    poolStartTime(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
    showEventIsLive(date, eventStatus) {
      if (
        eventStatus == "ONGOING" &&
        moment(date).isBefore(moment().add(30, "minutes"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDateYesterday(date) {
      return moment(date).isBefore(moment().startOf("day"));
    },
    isDateInThePast(date) {
      return moment(date).isBefore();
    },
    async toggleEventDetails() {
      if (this.eventDetails == null) {
        await this.loadEventDetails();
      }

      this.showDetails = !this.showDetails;
    },
    async toggleEventFavourite(id) {
      try {
        if (this.eventData.isUserFavourite) {
          const userFavourites = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                {
                  me{
                    favouriteEvents{
                      id
                      event{
                        id
                      }
                    }
                  }
                }
              `,
            },
          });

          const favouriteToRemove =
            userFavourites.data.data.me.favouriteEvents.find(
              (x) => x.event.id == this.eventData.id
            );

          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation {
                  DeleteFavouriteEvent(favouriteId: "${favouriteToRemove.id}")
                }
              `,
            },
          });

          this.$emit("isFavourite", false);
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Poof! Event unstarred.",
            type: "warning",
          });
        } else {
          const newFavourite = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation {
                  CreateEventFavourite(eventId: "${id}"){
                    id
                  }
                }
              `,
            },
          });
          if (newFavourite.data.errors) {
            if (newFavourite.data.errors[0].errorCode == "MAX_EXCEEDED") {
              this.$store.dispatch("showMessage", {
                show: true,
                message:
                  "20 starred events is the current limit. Unstar other events to star more.",
                type: "error",
              });
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Error",
                type: "error",
              });
            }
          } else {
            this.$emit("isFavourite", true);
            this.$store.dispatch("showMessage", {
              show: true,
              message:
                "Yay! You’ve just starred an event. It’s on your Dashboard.",
              type: "success",
            });
          }
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async loadEventDetails() {
      let eventData = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            event(eventId:"${this.eventData.id}"){
              id
              maxSpots
              playersCount
              checkInStartsAt
              checkInEndsAt
              isVerifyMode
              isResultSigningEnabled
              rounds{
                id
                pools{
                  id
                  date
                  layoutVersion{
                    id
                    holes{
                      id
                      par
                    }
                    layout{
                      id
                      name
                      type
                      course{
                        id
                        name
                      }
                    }
                  }
                }
              }
              registrations{
                id
                status
              }
            }
          }
        `,
        },
      });

      this.eventDetails = eventData.data.data.event;
    },
    trimEventName(eventName) {
      if (eventName.length > 28) {
        return eventName.substring(0, 25) + "...";
      }
      return eventName;
    },
    eventDateFormatted(date, end) {
      if (moment(date).isSame(end, "day")) {
        if (moment().isSame(date, "day")) {
          return moment(date).format("[TODAY] [at] HH:mm");
        } else if (moment().add(1, "days").isSame(date, "day")) {
          return moment(date).format("[TOMORROW] [at] HH:mm");
        } else if (moment().subtract(1, "days").isSame(date, "day")) {
          return moment(date).format("[Yesterday] [at] HH:mm");
        } else {
          return moment(date).format("ddd D MMM [at] HH:mm");
        }
      } else {
        if (moment().isSame(date, "day")) {
          if (moment().isBefore(date)) {
            return (
              moment(date).format("[TODAY] [at] HH:mm") +
              " to " +
              moment(end).format("ddd D MMM")
            );
          } else {
            return (
              "Started " +
              moment(date).format("[TODAY] [at] HH:mm") +
              " to " +
              moment(end).format("ddd D MMM")
            );
          }
        } else if (moment().add(1, "days").isSame(date, "day")) {
          return (
            moment(date).format("[TOMORROW] [at] HH:mm") +
            " to " +
            moment(end).format("ddd D MMM")
          );
        } else if (moment().subtract(1, "days").isSame(date, "day")) {
          return (
            "Started " +
            moment(date).format("[yesterday] [at] HH:mm") +
            " to " +
            moment(end).format("ddd D MMM")
          );
        } else {
          return (
            moment(date).format("ddd D MMM [at] HH:mm") +
            " to " +
            moment(end).format("ddd D MMM")
          );
        }
      }
    },
    dateformatter(date) {
      if (moment().isSame(date, "day")) {
        return moment(date).format("[TODAY] [at] HH:mm");
      } else if (moment().add(1, "days").isSame(date, "day")) {
        return moment(date).format("[TOMORROW] [at] HH:mm");
      } else if (moment().subtract(1, "days").isSame(date, "day")) {
        return moment(date).format("[Yesterday] [at] HH:mm");
      } else {
        return moment(date).format("D MMM [at] HH:mm");
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.round-pools-details {
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 24px;

    li {
      margin-bottom: 6px;
      p {
        font-size: 12px;
        @include Gilroy-Bold;
        margin-bottom: 0;
        span {
          @include Gilroy-Regular;
        }
      }
    }
  }
}
.course-details {
  margin-top: 20px;

  .course,
  .layout {
    font-size: 14px;
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      margin-bottom: 0;
      font-size: 14px;
    }
    b {
      font-size: 14px;
      @include Gilroy-Bold;
      margin-left: 4px;
    }
    svg {
      margin-left: 6px;
    }

    .custom-tag {
      margin: 0 0 0px 6px;
      font-size: 7px;
      text-transform: uppercase;
      background-color: $sunset;
      color: white;
      letter-spacing: 0.8px;
      padding: 2px 4px 1px 4px;
      border-radius: 6px;
      align-items: center;
      width: auto;
    }
  }

  .tags {
    border-bottom: 0px;
    margin: 12px 0 0px 0;
    ul {
      margin-bottom: 0;
    }
  }
}
.check-in {
  border-top: 1px solid $fog;
  width: calc(100% - 40px);

  padding: 20px 0;
  p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;

    b {
      @include Gilroy-Bold;
      display: inline;
    }

    svg {
      margin-right: 12px;
    }

    &.small {
      font-size: 12px;
      padding-left: 30px;
      margin: 0;
      flex-wrap: wrap;

      .dot {
        width: 4px;
        height: 4px;
        background: $midnight;
        display: block;
        border-radius: 50%;
        margin: 0 5px 1px 5px;
      }
    }
  }
}
.features-status {
  border-top: 1px solid $fog;
  border-bottom: 1px solid $fog;
  display: flex;
  justify-content: space-between;

  padding: 20px 0;
  p {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 14px;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border: 1px solid $sleet;
  padding: 18px 20px 26px 20px;

  .star-event {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .event-info {
    display: flex;
    flex-direction: column;
    position: relative;
    h3 {
      font-size: 20px;
      margin-bottom: 7px;
      @include Gilroy-Bold;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      padding-right: 40px;
    }
    p {
      font-size: 14px;
      margin-bottom: 8px;

      &.tour-name {
        font-size: 12px;
        color: $strom;
        margin-bottom: 12px;

        b {
          @include Gilroy-SemiBold;
        }
      }
    }
    h5 {
      font-size: 16px;
      @include Gilroy-Bold;
      margin-bottom: 9px;
    }

    .star {
      position: absolute;
      top: -4px;
      right: 0;
      cursor: pointer;
    }
  }

  .tags {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      margin-bottom: 24px;

      li {
        &.card-label {
          margin-right: 8px;
        }
      }
    }
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background: $cloud;
    cursor: pointer;
    transition: all ease 0.3s;
    position: relative;
    margin-left: auto;

    &:hover {
      background: $fog;
    }

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      z-index: 2;
    }
  }
  .status {
    width: 100%;
    border-top: 1px solid $fog;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .event-details {
      width: 100%;
      display: flex;
      flex-direction: column;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          padding: 0px 0 20px 0;
          font-size: 14px;

          svg {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }

    .event-ongoing {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 20px 0px 20px 0px;
    }
    .registration-closed {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 20px 0px 20px 0px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .registration-open {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 8px 0;
      padding: 20px 0px 20px 0px;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .event-finished {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 20px 0px 20px 0px;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .footer {
    display: flex;
    user-select: none;

    .view {
      display: block;
      background-color: $mist;
      font-size: 16px;
      @include Gilroy-Bold;
      display: flex;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;

      border-radius: 6px;
      letter-spacing: 0.5px;
      transition: all ease 0.3s;

      &:hover {
        background-color: $fog;
      }
    }
    .register {
      background-color: $twilight;
      display: flex;
      flex: 1;
      @include Gilroy-Bold;
      color: white;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 6px;
      border-left: 1px solid white;
      border-right: 1px solid $twilight;
      border-bottom: 1px solid $twilight;
      letter-spacing: 0.5px;

      &:hover {
        background-color: $twilightshade;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  main {
    min-width: 320px;
    padding: 20px 22px 28px 22px !important;

    section {
      width: 100%;
    }

    .event-info {
      h3 {
        font-size: 24px;
      }
    }
  }
}
</style>


