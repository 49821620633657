<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="[color]"
  >
    <path class="a" d="M24,12A12,12,0,1,1,12,0,12,12,0,0,1,24,12" />
    <circle class="b" cx="3" cy="3" r="3" transform="translate(9 9)" />
  </svg>
</template>

<script>
export default {
  name: "SpotsLeft",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.grey {
  .a {
    fill: $strom;
  }
}
.dusk {
  .a {
    fill: #ff0064;
  }
}
.a {
  fill: #0c9;
}
.b {
  fill: #fff;
}
</style>