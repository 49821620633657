<template>
  <svg
    :class="[color]"
    xmlns="http://www.w3.org/2000/svg"
    width="9.415"
    height="9.414"
    viewBox="0 0 9.415 9.414"
  >
    <g transform="translate(-866.293 458.707) rotate(-90)">
      <line class="a" x2="11.314" transform="translate(450 875) rotate(-45)" />
      <line
        class="a"
        x2="11.314"
        transform="translate(450 867.001) rotate(45)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FilterChipCross",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a {
    fill: none;
    stroke: #2d323c;
    stroke-width: 2px;
  }
}
.white {
  .a {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.twilight {
  .a {
    fill: none;
    stroke: #b40068;
    stroke-width: 2px;
  }
}
</style>