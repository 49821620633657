<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.243"
    height="26.828"
    viewBox="0 0 16.243 26.828"
    :class="direction"
  >
    <path
      class="a"
      d="M-4246.908,4701.461l-12-12-12,12"
      transform="translate(-4686.632 -4245.494) rotate(-90)"
    />
  </svg>
</template>

<script>
export default {
  name: "NextPreviousArrow",
  props: ["direction"],
};
</script>

<style lang="scss" scoped>
.right {
  transform: rotate(-180deg);
}
.a {
  fill: none;
  stroke: #05b5bc;
  stroke-width: 4px;
  transition: all ease 0.3s;
}

svg {
  &:hover {
    .a {
      stroke: darken(#05b5bc, 5%);
    }
  }
}
</style>